<template>
  <div id="committed-use-form-container">
    <spinner-component v-if="showSpinner" />
  </div>
</template>

<script>
import { defineAsyncComponent, onMounted, ref } from 'vue'
import { parsePhoneNumber } from 'libphonenumber-js'
import { trackCommittedDiscountSubmit } from '@/application/utils/tracking-scripts'

export default {
  name: 'SignInForm',
  components: {
    'spinner-component': defineAsyncComponent(() => import('@/application/components/layout/SpinnerComponent.vue')),
  },
  props: {
    license: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const showSpinner = ref(true)
    const fieldLicenseMap = [
      { formCode: 'firstname', licenseCode: 'first_name' },
      { formCode: 'lastname', licenseCode: 'last_name' },
      { formCode: 'email', licenseCode: 'email' },
      { formCode: 'company', licenseCode: 'company_name' },
      { formCode: 'website', licenseCode: 'company_site' },
    ]

    onMounted(() => {
      const scriptElement = document.createElement('script')
      scriptElement.setAttribute('src', '//js-eu1.hsforms.net/forms/v2.js')
      scriptElement.setAttribute('charset', 'utf-8')
      scriptElement.setAttribute('type', 'text/javascript')
      document.body.appendChild(scriptElement)
      scriptElement.addEventListener('load', () => {
        window.hbspt.forms.create({
          region: 'eu1',
          portalId: process.env.VUE_APP_HUBSPOT_PORTAL_ID,
          formId: process.env.VUE_APP_HUBSPOT_FORM_ID,
          target: '#committed-use-form-container',
          onFormReady: function($form) {
            showSpinner.value = false
            fieldLicenseMap.forEach(({ formCode, licenseCode }) => {
              if (props.license[licenseCode]) {
                $form.find(`input[name="${formCode}"]`).val(props.license[licenseCode]).change()
              }
            })
            if (props.license.phone_number) {
              const phoneInput = $form.find('input[name="phone"]')
              const rowPhone = phoneInput ? phoneInput[0].parentNode : null
              const parsedPhone = parsePhoneNumber(props.license.phone_number)
              if (rowPhone && parsedPhone && parsedPhone.country) {
                window.$(rowPhone).find('select').val(parsedPhone.country).change()
              }
              $form.find('input[name="phone"]').val(parsedPhone && parsedPhone.nationalNumber ? parsedPhone.nationalNumber : props.license.phone_number).change()
            }

            if (props.license.country) {
              $form.find('select[name="company_country"]').val(props.license.country).change()
            }
          },
          onFormSubmit: function() {
            trackCommittedDiscountSubmit()
          },
        })
      })
    })

    return {
      showSpinner,
    }
  },
}
</script>
